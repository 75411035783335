"use client";
import {
  Box,
  Button,
  Container,
  GridItem,
  Heading,
  VStack,
  Text,
} from "@chakra-ui/react";
import Image from "next/image";
import { FC } from "react";

type GlobalErrorProps = {
  name: string;
  message?: string;
};

const GlobalError: FC<GlobalErrorProps> = ({ name, message }) => {
  return (
    <GridItem colSpan={2} pl="2" pr="4" py="4">
      <Container>
        <VStack>
          <Box className="rounded-full overflow-hidden">
            <Image
              src={"/img/error.png"}
              alt="Error"
              width={400}
              height={400}
            />
          </Box>
          <Heading className="text-center">{name}</Heading>
          {message && <Text>{message}</Text>}
          <Button as="a" href="/">
            Return home
          </Button>
        </VStack>
      </Container>
    </GridItem>
  );
};

export default GlobalError;
